import React from 'react'
import Slider from 'react-slick'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"

import Layout from "../components/layout"
import { MDXSharpImg, MDXSrcImg, SafeFluid } from '../components/images';
import { Safe } from '../components/utils';
// import SEO from "../components/seo"

const shortcodes = {
   Img,
}

// function Slider(props) {
//    const isLoggedIn = props.isLoggedIn;
//    if (isLoggedIn) {
//    return <UserGreeting />;
//    }
//    return <GuestGreeting />;
// }

// <Slider isLoggedIn={false} />

/* <div className={i === 0 ? `slideshow-item shown` : `slideshow-item`}>
  {i === 1 && <span>show something</span>}
  {i === 2 && <span>show something else</span>}
  {i > 2 && <span>show default</span>}
</div> */

const Project = ({ data }) => {
   const project = data.mdx
   const thumbnail = project.fields.thumbnail.childImageSharp.fluid
   const slides = project.fields.slides
   const images = project.fields.images
   const credits = project.frontmatter.credits
   const recognition = project.frontmatter.recognition
   const gallery = project.fields.gallery

   const imgs = {};
   if (images) {
      images.forEach((image, i) => {
         const { childImageSharp: c, publicURL } = Safe(image);
         const { fluid: f } = Safe(c);
         imgs[`Image${i + 1}`] = ({ align, width }) =>
         f ? (
            <MDXSharpImg align={align} width={width} fluid={SafeFluid(f)} />
         ) : (
            <MDXSrcImg align={align} width={width} src={publicURL || ''} />
         );
      });
   }

   return (
      // <Layout>
      //    <MDXProvider components={shortcodes}>
      //       <MDXRenderer fields={data.mdx.fields} frontmatter={data.mdx.frontmatter}>{data.mdx.body}</MDXRenderer>
      //    </MDXProvider>
      // </Layout>

      <Layout>
         {/* <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} /> */}
         <article className="sheet">
            <div className="sheet__inner">
               <div className="sheet__banner">
                  <Img fluid={thumbnail} />
               </div>
               <h1 className="sheet__title">{project.frontmatter.title}</h1>
               <div className="sheet__lead">{project.frontmatter.summary}</div>
               <hr />
               <QuickLinks credits={credits} recognition={recognition} gallery={gallery} />
               <SliderGallery slides={slides} />
               <div className="sheet__body">
                  <MDXProvider components={shortcodes}>
                     <MDXRenderer imgs={imgs} fields={project.fields} frontmatter={project.frontmatter}>{project.body}</MDXRenderer>
                  </MDXProvider>
               </div>
               <Credits credits={credits} />
               <Recognition recognition={recognition} />
               <Gallery images={gallery} />
            </div>
         </article>
      </Layout>
   )
}

function QuickLinks({ credits, recognition, gallery }) {
   if (!credits && !recognition && !gallery) {
      return null;
   }
   return (
      <div>
         <p className="sheet__quicklinks">&nbsp;•&nbsp;
         {/* Conditional JSX React HTML */}
         { credits &&
            <span><a href="#credits">Credits</a>&nbsp;•&nbsp;</span>
         }
         { recognition &&
            <span><a href="#recognition">Recognition</a>&nbsp;•&nbsp;</span>
         }
         { gallery &&
            <span><a href="#gallery">Gallery</a>&nbsp;•&nbsp;</span>
         }
         </p>
         <hr />
      </div>
   );
}

/* Example for conditional JSX React HTML */
/* <div className={i === 0 ? `slideshow-item shown` : `slideshow-item`}>
   {i === 1 && <span>show something</span>}
   {i === 2 && <span>show something else</span>}
   {i > 2 && <span>show default</span>}
</div> */

// If there are slides, generate the HMTL for the component.
function SliderGallery({ slides }) {
   if (!slides) {
      return null;
   }
   return (
      <div className="sheet__slider">
         <Slider slidesToShow={2} infinite arrows autoplay autoplaySpeed={6000}>
         {slides.map((slides, i) => (
            <Img key={i} fluid={slides.childImageSharp.fluid} />
         ))}
         </Slider>
      </div>
   );
}

function Credits({ credits }) {
   if (!credits) {
      return null;
   }
   return (
      <div className="sheet__body">
         <h2 id="credits">Credits</h2>
         <ul>
            {credits.map((credits, i) => (
               <li key={i}>
                  {credits.link &&
                     <span><a href={credits.link}>{credits.name}</a> – {credits.role}</span>
                  }
                  {!credits.link &&
                     <span>{credits.name} – {credits.role}</span>
                  }
               </li>
            ))}
         </ul>
      </div>
   );
}

function Recognition({ recognition }) {
   if (!recognition) {
      return null;
   }
   return (
      <div className="sheet__body">
         <h2 id="recognition">Recognition</h2>
         <ol className="sheet__recognition">
            {recognition.map((recognition, i) => (
               <li className="sheet__recognitionitem" key={i}>
                  <strong>{recognition.event}</strong>
                  <em>{recognition.status}</em>
                  {recognition.location} — {recognition.datesPretty}
               </li>
            ))}
         </ol>
      </div>
   );
}

function Gallery({ images }) {
   if (!images) {
      return null;
   }
   return (
      <div className="sheet__body">
         <h2 id="gallery">Gallery</h2>
         <div className="sheet__gallery">
            {images.map((images, i) => (
               <Img className="sheet__galleryimage" key={i} fluid={images.childImageSharp.fluid} />
            ))}
         </div>
      </div>
   );
}

export const query = graphql`
   query($slug: String!) {
      mdx(fields: { slug: { eq: $slug } }) {
         frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            summary
            credits {
               name
               link
               role
            }
            recognition {
               event
               status
               location
               dates
               datesPretty
               link
               award
               detail
            }
         }
         fields {
            thumbnail {
               childImageSharp {
                  fluid(maxWidth: 600, maxHeight: 250, cropFocus: CENTER) {
                     ...GatsbyImageSharpFluid
                  }
               }
            }
            slideCount
            slides {
               childImageSharp {
                  fluid(maxWidth: 200, maxHeight: 150, cropFocus: ATTENTION) {
                     ...GatsbyImageSharpFluid
                  }
               }
            }
            imageCount
            images {
               childImageSharp {
                  fluid(maxWidth: 600) {
                     ...GatsbyImageSharpFluid
                  }
               }
            }
            galleryCount
            gallery {
               childImageSharp {
                  fluid(maxWidth: 600) {
                     ...GatsbyImageSharpFluid
                  }
               }
            }
         }
         body
         excerpt
      }
   }
`

export default Project